.letter-card-preview {
  max-height: 50px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 1em;
  margin-bottom: 1em;
}

.letter-card:hover {
  cursor: pointer;
}
