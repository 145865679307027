.logo {
  cursor: pointer;
  width: 15%;
  height: 15%;
  margin-top: -20px;
}

.navbar {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 10px 0px;
}

.nav-link {
  margin-top: 20px;
  padding: 0.5em 0;
}
